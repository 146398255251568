import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';

export const snackTypes = Object.freeze({
  ERROR: 'fail',
  SUCCESS: 'success'
});

const DELAY = 12000;
const FADE_DELAY = 500;

const typeStyles = {
  [snackTypes.ERROR]: styles.error,
  [snackTypes.SUCCESS]: styles.success,
};

const CloseButton = ({ onClose }) => (
  <div className={ styles.closeButton }
       onClick={ onClose }>
    <div className={ styles.closeButtonCross }/>
  </div>);

/**
 * Snack will automatically fade in {DELAY} ms
 */
const Snack = ({classes, id, message, remove, type = snackTypes.ERROR}) => {
  const [isFade, setFade] = useState(false);

  const onClose = () => {
    remove(id)
  };

  useEffect(() => {
    const lifeTimer = setTimeout(() => remove(id), DELAY);
    const fadeTimer = setTimeout(() => setFade(true), DELAY - FADE_DELAY);
    return () => {
      clearTimeout(lifeTimer);
      clearTimeout(fadeTimer);
    }
  }, [id]);

  return (
    <div className={ classNames(styles.snack, typeStyles[type], {[styles.snackFade]: isFade}) }>
      <CloseButton onClose={onClose}/>
      {message}
    </div>
  );
};

export default Snack;