import React from 'react';
import { withNamespaces } from 'react-i18next';

const Home = ({ t }) => {
  return (
    <div>
      { t('headerTitle') }
    </div>
  );
};

export default withNamespaces('common')(Home);