import React, { createContext, useContext, useReducer } from 'react';
import Snack from './snack';
import uuid from 'uuid/v4';
import styles from './index.module.scss';

/**
 * Snackbar shows notifications at the top right corner
 * which will fade in 5 seconds
 *
 * How to use:
 * 1) Wrap the App in SnackbarProvider
 * 2) In a component: const addSnackbar = useAddSnackbar();
 * 3) Call: addSnackbar({message: 'Error has just occurred'});
 */

const SnackbarContext = new createContext(null);

/**
 * Provider for Snackbar
 */
export const SnackbarProvider = ({children}) => {
  const {list, add, remove} = useSnackbarStack();

  return (
    <SnackbarContext.Provider value={add}>
      {children}
      <div className={styles.snackContainer}>
        {list.map(snack => <div key={snack.id}><Snack remove={remove} {...snack} /></div>)}
      </div>
    </SnackbarContext.Provider>
  )
};

/**
 * Returns a function to add snackbar
 * example of usage: add({message: 'Error has just happened'})
 */
export const useAddSnackbar = () => useContext(SnackbarContext);


/**
 * Snackbar stack model
 */
const useSnackbarStack = () => {
  const [list, dispatch] = useReducer(
    (state, action) => actionHandlers[action.type] ? actionHandlers[action.type](state, action) : state, []);
  return {
    list,
    add: payload => dispatch({type: actions.ADD, payload}),
    remove: id => dispatch({type: actions.REMOVE, id}),
  };
};

const actionHandlers = {
  add: (state, action) => {
    action.payload.id = uuid();
    return [action.payload, ...state]
  },
  remove: (state, action) => state.filter(({id}) => action.id !== id)
};

const actions = Object.freeze({ADD: 'add', REMOVE: 'remove'});