import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import Home from './components/pages/home/home';
import './config';
import { BrowserRouter } from 'react-router-dom';
import i18n from './i18n/i18n';
import { I18nextProvider } from 'react-i18next';
import MediaQueryProvider from './components/layout/mediaQuery';
import { SnackbarProvider } from './components/notification/snackbarStack';

const Ceremony = React.lazy(() =>
  import('./components/pages/ceremony/ceremony')
);
const Unsubscribe = React.lazy(() =>
  import('./components/pages/unsubscribe/unsubscribe')
);
const Preneed = React.lazy(() =>
  import('./components/pages/preneed/preneed')
);
const MEDIA_QUERY_MOBILE = "(max-width: 896px)";

class App extends Component {
  render() {
    return (
      <I18nextProvider i18n={ i18n }>
        <SnackbarProvider>
          <BrowserRouter>
            <MediaQueryProvider value={ MEDIA_QUERY_MOBILE }>
              <Suspense fallback={ <div>Loading...</div> }>
                <Switch>
                  <Route
                    path='/ceremony'
                    render={ props => <Ceremony { ...props } /> }
                  />
                  <Route
                    path='/preneed'
                    render={ props => <Preneed { ...props } /> }
                  />
                  <Route
                    path='/unsubscribe'
                    render={ props => <Unsubscribe { ...props } /> }/>
                  <Route component={ Home }/>
                </Switch>
              </Suspense>
            </MediaQueryProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </I18nextProvider>
    );
  }
}

export default App;
