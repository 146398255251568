import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { allowMultilingual } from '../components/pages/ceremony/constants';

import moment from 'moment';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';
import 'moment/locale/es-us';

const DEFAULT_LANGUAGE = 'en-CA';
let WHITELIST = [DEFAULT_LANGUAGE, 'fr-CA', 'es-US'];

if (!allowMultilingual) {
  WHITELIST = [DEFAULT_LANGUAGE];
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    react: {
      wait: true,
      bindI18n: 'languageChanged',
      bindStore: false
    },
    fallbackLng: DEFAULT_LANGUAGE,
    ns: 'common',
    whitelist: WHITELIST,
    backend: {
      loadPath: '../translations2/{{lng}}/{{ns}}.json'
    }
  });

export const getCurrentLanguage = () => {
  return i18n.language;
};

export const changeLanguage = (lang) => {
  moment.locale(lang.toLowerCase());
  return i18n.changeLanguage(lang);
};

function setMomentDefaultLanguage() {
  let language = localStorage.getItem('i18nextLng') || DEFAULT_LANGUAGE;
  moment.locale(language.toLowerCase());
}

setMomentDefaultLanguage();

export default i18n;