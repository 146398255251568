import React, { useState, useEffect, createContext } from 'react';

export const MediaQueryContext = createContext(false);

const MediaQueryProvider = ({children, value}) => {
  const isMatch = useMediaQueryMatch(value);
  return (
    <MediaQueryContext.Provider value={isMatch}>
      {children}
    </MediaQueryContext.Provider>
  )
};

const useMediaQueryMatch = value => {
  const [isMatch, setMatch] = useState(false);
  const queryHandler = queryList => {
    setMatch(queryList.matches);
  };

  useEffect(() => {
    const queryList = window.matchMedia(value);
    queryHandler(queryList);
    queryList.addListener(queryHandler);
    return () => queryList.removeListener(queryHandler);
  }, [value]);

  return isMatch;
};

export default MediaQueryProvider;