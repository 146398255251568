import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { awsConfig } from './config';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { Auth } from 'aws-amplify';
import { ApolloProvider } from 'react-apollo';
import { Rehydrated } from 'aws-appsync-react';


export const client = new AWSAppSyncClient({
  url: awsConfig.API.aws_appsync_graphqlEndpoint,
  region: awsConfig.Auth.region,
  auth: {
    type: AUTH_TYPE.AWS_IAM,
    credentials: () => Auth.currentCredentials(),
  },
  offlineConfig: {
    callback: (err, succ) => {
      if(err) {
        const { mutation, variables } = err;
        console.warn(`ERROR for ${mutation}`, err);
      } else {
        const { mutation, variables } = succ;
        console.info(`SUCCESS for ${mutation}`, succ);
      }
    },
  },
});

const WithProvider = () => (
  <ApolloProvider client={client}>
    <Rehydrated>
      <App />
    </Rehydrated>
  </ApolloProvider>
);

ReactDOM.render(<WithProvider/>, document.getElementById('root'));


