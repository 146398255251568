import Amplify, { Auth } from 'aws-amplify';
import {
  fillInWithEnvVariables,
  getEnvVariable
} from '../utils/environmentVariables';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

export const API_NAME = 'ceremony_api';
export const PRENEED_API_NAME = 'preneed_api';
export const CF_API_NAME = 'cloudfront_api';

export const PAYPAL_ID = fillInWithEnvVariables(
  '%REACT_APP_PAYPAL_ID%',
  getEnvVariable
);
export const PAYPAL_ENV = fillInWithEnvVariables(
  '%REACT_APP_PAYPAL_ENV%',
  getEnvVariable
);
/**
 * Configure all aws services here
 */
export const awsConfig = fillInWithEnvVariables(
  {
    Auth: {
      userPoolId: '%REACT_APP_POOL_ID%',
      userPoolWebClientId: '%REACT_APP_POOL_CLIENT_ID%',
      region: '%REACT_APP_REGION%',
      identityPoolId: '%REACT_APP_IDENTITY_POOL_ID%',
      preneedAPIKey: '%REACT_APP_PRENEED_API_KEY%'
    },
    API: {
      endpoints: [
        {
          name: `${API_NAME}`,
          endpoint: '%REACT_APP_API_ENDPOINT%',
          region: '%REACT_APP_REGION%'
        },
        {
          name: `${CF_API_NAME}`,
          endpoint: '%REACT_APP_CF_ENDPOINT%',
          region: '%REACT_APP_REGION%'
        },
        {
          name: `${PRENEED_API_NAME}`,
          endpoint: '%REACT_APP_API_ENDPOINT%',
          region: '%REACT_APP_REGION%'
        },
      ],
      aws_appsync_graphqlEndpoint: '%REACT_APP_APPSYNC_GRAPHQL_ENDPOINT%',
      aws_appsync_region: '%REACT_APP_APPSYNC_REGION%',
      aws_appsync_authenticationType: 'AWS_IAM',
      aws_appsync_apiKey: '%REACT_APP_APPSYNC_API_KEY%'
    },
    Storage: {
      AWSS3: {
        bucket: '%REACT_APP_BUCKET_NAME%'
      }
    }
  },
  getEnvVariable
);

const headers = async () => {
  try {
    return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
  } catch (e) {
    return {};
  }
};

awsConfig.API.endpoints[0].custom_header = headers;
awsConfig.API.endpoints[1].custom_header = headers;

Amplify.configure(awsConfig);

export const getUserPool = () =>
  new CognitoUserPool({
    UserPoolId: awsConfig.Auth.userPoolId,
    ClientId: awsConfig.Auth.userPoolWebClientId
  });
